import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { genericMemo, IterableMap } from "@redotech/react-util/component";
import { useHandler } from "@redotech/react-util/hook";
import { Input } from "@redotech/ui/form";
import * as classNames from "classnames";
import {
  Fragment,
  Key,
  ReactElement,
  ReactNode,
  useEffect,
  useId,
  useState,
} from "react";
import { Dropdown, DropdownOption } from "./dropdown";
import CheckIcon from "./icon-old/check.svg";
import ChevronDownIcon from "./icon-old/chevron-down.svg";
import { LabeledInput, LabelTheme } from "./labeled-input";
import * as selectDropdownCss from "./select-dropdown.module.css";

export namespace SelectDropdownTheme {
  export const BRAND = Symbol("Brand");
  export const GHOST = Symbol("Ghost");
}

export type SelectDropdownTheme =
  | typeof SelectDropdownTheme.BRAND
  | typeof SelectDropdownTheme.GHOST;

export interface OptionConfig {
  id: string;
  name: string;
  Render?(): ReactElement;
}

export interface BaseDropdownProps<T> {
  children(option: T): ReactNode;
  keyFn?(option: T, index: number): Key;
  id?: string;
  options: readonly T[][] | readonly T[];
  className?: string;
}

export interface SingleSelectDropdownProps<T> extends BaseDropdownProps<T> {
  value: T | undefined;
  valueChange(value: T | undefined): void;
  display?(option: T): ReactNode;
  closeOnSelect?: boolean;
  placeholder?: string;
  disabled?: boolean;
  allowNull?: boolean;
  showChevron?: boolean;
  icon?: ReactNode;
  actionButton?: ReactNode;
  theme?: SelectDropdownTheme;
}

export interface MultiSelectDropdownProps<T> extends BaseDropdownProps<T> {
  value: readonly T[] | undefined;
  valueChange(value: readonly T[] | undefined): void;
  display?(option: readonly T[]): ReactNode;
  closeOnSelect?: boolean;
  disabled?: boolean;
  flip?: boolean; // false prevents dropdowns from changing from down to up when there's not enough space
  placement?: string;
  showChevron?: boolean;
  icon?: ReactNode;
  actionButton?: ReactNode;
  theme?: SelectDropdownTheme;
  checkElement?(option: T): ReactNode;
  reducedPadding?: boolean;
  fitToAnchor?: boolean;
}

const themeClass = {
  [SelectDropdownTheme.BRAND]: selectDropdownCss.brand,
  [SelectDropdownTheme.GHOST]: selectDropdownCss.ghost,
};

export const SelectDropdown = genericMemo(function SelectDropdown<T>({
  children,
  value,
  valueChange,
  display,
  placeholder = "",
  disabled = false,
  allowNull = false,
  showChevron = true,
  icon,
  closeOnSelect = false,
  actionButton,
  theme = SelectDropdownTheme.BRAND,
  ...props
}: SingleSelectDropdownProps<T>) {
  const [arrayValue, setArrayValue] = useState<[T?]>([]);

  useEffect(() => {
    if (value === undefined) {
      setArrayValue([]);
    } else {
      setArrayValue([value]);
    }
  }, [value]);

  const onChange = useHandler((newValue: T[]) => {
    if (newValue.length) {
      // The last value is the newest value
      valueChange(newValue[newValue.length - 1]);
    }
  });

  const singleDisplay = useHandler((option: T[]) => {
    if (allowNull) {
      if (option?.[0] !== undefined) {
        return display ? display(option[0]) : children(option[0]);
      }
    } else {
      if (option?.[0] !== undefined && option?.[0] !== null) {
        return display ? display(option[0]) : children(option[0]);
      }
    }
    return placeholder;
  });

  return (
    <MultiSelectDropdown
      {...props}
      actionButton={actionButton}
      closeOnSelect
      disabled={disabled}
      display={singleDisplay}
      icon={icon}
      showChevron={showChevron}
      theme={theme}
      value={arrayValue}
      valueChange={onChange}
    >
      {children}
    </MultiSelectDropdown>
  );
});

export const MultiSelectDropdown = genericMemo(function MultiSelectDropdown<T>({
  children,
  display,
  keyFn,
  id, // TODO: implement
  options,
  value,
  valueChange,
  className,
  closeOnSelect = false,
  disabled = false,
  flip = true,
  placement = undefined,
  showChevron = true,
  icon,
  actionButton,
  theme = SelectDropdownTheme.BRAND,
  checkElement,
  reducedPadding = false,
  fitToAnchor = true,
}: MultiSelectDropdownProps<T>) {
  const [container, setContainer] = useState<HTMLElement | null>(null);
  const [popover, setPopover] = useState<HTMLElement | null>(null);

  const [open, setOpen] = useState(false);

  const themeClassName = themeClass[theme];

  const onClickAway = useHandler((e: MouseEvent | TouchEvent) => {
    // Don't close if toggling an option in a multi-select dropdown
    if (!closeOnSelect && popover?.contains(e.target as Node)) {
      return;
    }
    setOpen(false);
  });
  let label: ReactNode;
  if (display) {
    label = display(value || []);
  } else {
    label = value?.map((v, i) => (
      <Fragment key={keyFn ? keyFn(v, i) : i}>{children(v)}</Fragment>
    ));
  }

  const optionIsSelected = (option: T, optionIndex: number): boolean => {
    if (keyFn) {
      const optionKey = keyFn(option, optionIndex);
      return value?.some((v, i) => keyFn(v, i) === optionKey) || false;
    } else {
      return value?.includes(option) || false;
    }
  };

  const getDropdownOption = (option: T, index: number) => {
    function action() {
      if (optionIsSelected(option, index)) {
        const valueAfterRemoval = (value || []).filter((item, idx) => {
          if (keyFn) {
            return keyFn(item, idx) !== keyFn(option, index);
          } else {
            return item !== option;
          }
        });

        valueChange(valueAfterRemoval);
        if (closeOnSelect) setOpen(false);
      } else {
        valueChange([...(value || []), option]);
        if (closeOnSelect) setOpen(false);
      }
    }
    return (
      <DropdownOption action={action} disabled={disabled}>
        <div>{children(option)}</div>
        {optionIsSelected(option, index) &&
          (checkElement ? (
            checkElement(option)
          ) : (
            <CheckIcon className={selectDropdownCss.check} />
          ))}
      </DropdownOption>
    );
  };

  return (
    <div
      className={classNames(selectDropdownCss.container, className)}
      ref={setContainer}
    >
      <ClickAwayListener onClickAway={onClickAway} touchEvent={false}>
        <button
          className={classNames(selectDropdownCss.select, themeClassName, {
            [selectDropdownCss.reducedPadding]: reducedPadding,
          })}
          disabled={disabled}
          onClick={(e) => {
            e.preventDefault();
            setOpen((value) => !value);
          }}
        >
          {icon}
          {label}
          {showChevron && (
            <ChevronDownIcon className={selectDropdownCss.selectIcon} />
          )}
        </button>
      </ClickAwayListener>
      <Dropdown
        anchor={container}
        fitToAnchor={fitToAnchor}
        flip={flip}
        open={open}
        placement={placement}
        ref={setPopover}
      >
        <IterableMap
          items={
            Array.isArray(options[0]) ? (options as T[][]) : [options as T[]]
          }
          keyFn={(_, index) => index}
        >
          {(options, index) => (
            <>
              <IterableMap items={options} keyFn={keyFn || ((_, idx) => idx)}>
                {getDropdownOption}
              </IterableMap>
              {Array.isArray(options[0]) && index < options.length - 1 && (
                <hr className={selectDropdownCss.line} />
              )}
            </>
          )}
        </IterableMap>
        {actionButton && actionButton}
      </Dropdown>
    </div>
  );
});

export const FormSelectDropdown = genericMemo(function FormSelectDropdown<T>({
  description,
  label,
  input,
  valueChange,
  disabled = false,
  labelTheme,
  ...props
}: {
  description?: ReactNode | ReactNode[];
  label: string;
  input: Input<T>;
  valueChange?(value: T): void;
  disabled?: boolean;
  labelTheme?: LabelTheme;
} & Omit<SingleSelectDropdownProps<T>, "value" | "valueChange">) {
  const id = useId();
  const onChange = (value: T) => {
    input.setValue(value);
    if (valueChange) {
      valueChange(value);
    }
  };
  return (
    <LabeledInput
      description={description}
      errors={input.changed ? input.errors : []}
      id={id}
      label={label}
      theme={labelTheme}
    >
      <SelectDropdown
        disabled={disabled}
        value={input.value}
        valueChange={onChange}
        {...props}
      />
    </LabeledInput>
  );
});

export const FormMultiSelectDropdown = genericMemo(
  function FormMultiSelectDropdown<T>({
    description,
    label,
    input,
    valueChange,
    labelTheme,
    checkElement,
    ...props
  }: {
    description?: ReactNode | ReactNode[];
    label: string;
    valueChange?(value: T[]): void;
    labelTheme?: LabelTheme;
    input: Input<T[]>;
  } & Omit<MultiSelectDropdownProps<T>, "value" | "valueChange">) {
    const id = useId();
    const onChange = (value: T[]) => {
      input.setValue(value);
      if (valueChange) {
        valueChange(value);
      }
    };
    return (
      <LabeledInput
        description={description}
        errors={input.changed ? input.errors : []}
        id={id}
        label={label}
        theme={labelTheme}
      >
        <MultiSelectDropdown
          checkElement={checkElement}
          value={input.value}
          valueChange={onChange}
          {...props}
        />
      </LabeledInput>
    );
  },
);
