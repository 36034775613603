import type { Json } from "@redotech/json/json";
import { CountryCode } from "@redotech/locale/countries";
import type { Equal } from "@redotech/util/equal";
import {
  arrayEqual,
  booleanEqual,
  objectEqual,
  optionalEqual,
  stringEqual,
} from "@redotech/util/equal";
import type { Decimal128Extended } from "bson";
import type { Stripe } from "stripe";
import type { AdLinkType } from "./ad-link";
import type { BrandKit } from "./brand-kit";
import type { ResponseLength } from "./concierge-conversation/concierge-conversation-message-definition";
import type { ConversationTag } from "./conversation";
import type { Coverage, PaymentModel } from "./coverage";
import type { AdBlock } from "./customer-accounts/ad-block";
import type { LanguageResourceOverride } from "./localization/resource";
import type { DiscountSettings, DiscountValueType } from "./order-discount";
import type { Parcel, ServiceLevel, Upcharge } from "./outbound-labels";
import type { PaymentMethod } from "./payout";
import type { Bundle, BundleRules } from "./return-flow";
import type { PriceOption, SkuOption } from "./return-flow/condition";
import type { TriggerType } from "./return-flow/trigger";
import type { GetUser } from "./user";

export enum LocationCondition {
  PRODUCT_TAGS = "product_tags",
  DAMAGED = "damaged",
  NAME = "name",
  MULTIPLE_CHOICE = "multiple_choice",
  RETURN_REASON = "return_reason",
  COLLECTIONS = "collections",
  ORDER_TAGS = "order_tags",
  CUSTOMER_TAGS = "customer_tags",
  DISCOUNTS = "discounts",
  PRICE = "price",
  SKU = "sku",
  SALES_CHANNEL = "sales_channel",
  CUSTOMER_COUNTRY = "customer_country",
  CUSTOMER_ADDRESS = "customer_address",
  FINAL_SALE_RETURNS = "final_sale_returns",
}

export enum ShippingInsurance {
  DISABLED = "disabled",
  ENABLED = "enabled",
}

export enum ExchangesSelection {
  RETURN_APP = "return_app",
  STORE_WEBSITE = "store_website",
}

export interface GetTeam {
  roles: string[];
  _id: string;
  email: string;
  createdAt: string;
  firstName: string;
  lastName: string;
  name: string;
  updatedAt: string;
  images: string[];
  updatePermissionsLink?: string;
  platform: "shopify" | "commerce-cloud" | "commentsold";
  team: Team;
}

export type AttachmentStrategy =
  | "checkbox"
  | "single-toggle"
  | "double-toggle"
  | "drop-down"
  | "check-out"
  | "double-check-out"
  | "cart-card";

export type textSizes =
  | ""
  | "extraSmall"
  | "small"
  | "medium"
  | "large"
  | "extraLarge";

export type Icons = "gift" | "shield" | "lock";

export type DividerLinesType = "none" | "above" | "below" | "above and below";

export interface CheckoutUICustomizationSettings {
  titleTextSize: textSizes;
  subtextSize: textSizes;
  dividerLines: DividerLinesType;
  titleOnly: boolean;
}

export type LabelExpirationStrategy = "return" | "order" | "delivery";

export const upsellCardDefaultText = "You might also like";

export enum UpsellProductSource {
  RECOMMENDATIONS = "recommendations",
  COLLECTION = "collection",
  METAFIELD = "metafield",
  TAG = "tag",
}

export interface PriceBracket {
  value: number;
  pricePoint: number;
}
export interface PricingRuleSet {
  type: "fixed" | "percentage";
  countries: string[];
  priceBrackets: PriceBracket[];
}

export enum DynamicReturnTypes {
  ITEM_COUNT = "item-count",
  CART_VALUE = "cart-value",
}

export enum CustomerPortalVersion {
  V3_0 = "v3.0",
  V3_5 = "v3.5",
}

export interface DynamicReturnsPricingItemCount {
  type: DynamicReturnTypes.ITEM_COUNT;
  pricePerAdditonalItem: number;
  maxPrice: number;
}

export interface DynamicReturnsPricingCartValue {
  type: DynamicReturnTypes.CART_VALUE;
  maxPrice: number;
  pricingRuleSet: PricingRuleSet;
}

export type DynamicReturnPricing =
  | DynamicReturnsPricingCartValue
  | DynamicReturnsPricingItemCount;
interface ProcessingAutomation {
  exchanges: ProcessEvent;
  refunds: ProcessEvent;
  store_credit: ProcessEvent;
  exchanges_delay: number;
  refunds_delay: number;
  store_credit_delay: number;
}

export interface StripeData {
  customer_id: string;
  card?: Stripe.Card | null;
  paymentMethod?: PaymentMethod;
  secondaryPaymentMethod?: PaymentMethod;
}

export enum RedoItemFulfillment {
  NONE = "none",
  PARTIAL = "partial",
  IMMEDIATE = "immediate",
}

export interface Automation {
  enable: boolean;
  returnProcessing: ProcessingAutomation;
  claimProcessing: ProcessingAutomation;
  fulfill_redo_item: RedoItemFulfillment;
  returnOrderTags: {
    onRefund: string[];
  };
}

export interface Team {
  address?: Address;
  accessToken: string;
  storefrontAccessToken?: string;
  accessScope: string[];
  billingFailure: "allow" | "restrict";
  billingMethod: string;
  customer_email: string;
  publicAddress?: Address;
  damagedAddress?: Address;
  automation: Automation;
  failed_payouts: number;
  shutdown_date: string;
  _id: string;
  email: string;
  adminName: string;
  adminPhone: string;
  intercomId: string;
  lastOrderSync: string;
  orderBackfillWatermark: string;
  orderBackfillMin: string;
  merchantPricePerOrder?: string;
  name: string;
  onboarding: Onboarding;
  notification_email: string;
  notify_on_return_created: boolean;
  notify_on_delivery: boolean;
  notify_on_needs_review: boolean;
  claim_notification_email: string;
  notify_on_claim_created: boolean;
  notify_on_claim_delivery: boolean;
  notify_on_claim_needs_review: boolean;
  portal: Portal;
  pricePerOrder: string;
  receipt_email: string;
  returnLocationId?: string;
  settings: Settings;
  storeUrl: string;
  stripe?: StripeData;
  users: TeamUser[];
  widget_slug: string;
  storePassword: string;
  _shopify: Record<string, any>;
  createdAt: string;

  save(): Promise<void>;
  getStorefrontAccessToken(): Promise<string | null>;
  shouldAllowInstall(): Promise<boolean>;
  hasStripeCard(): boolean;
}

export interface DateRange {
  start: string | null;
  end: string | null;
}

export enum DiscountDistributionMethod {
  FAMILY = "DistributeAcrossFamily",
  ORDER = "DistributeAcrossOrder",
  LINE_ITEM = "LineItem",
}

export interface PagesData {
  pageId: string;
  pageName: string;
  pageAccessToken: string;
  instagramBusinessAccountId?: string;
  instagramUsername?: string;
}

export interface QuickLink {
  title: string;
  url: string;
}

export enum AccountTabSectionType {
  PRODUCT_RECOMMENDATIONS = "product_recommendations",
  VIEWED_PRODUCTS = "viewed_products",
  RECENT_ORDERS = "recent_orders",
}

export enum CloseActionMethod {
  NEXT = "next",
  TABLE = "table",
  STAY = "stay",
}

export interface AccountTabSection {
  type: AccountTabSectionType;
  header: string;
  enabled: boolean;
}

export interface EmailInfo {
  name: string;
  email: string;
  integrationKind: string;
}

export interface SupportAiSettings {
  enabled: boolean;
  copilotEnabled: boolean;
  autoGenerateResponses?: boolean;
}

export interface PackageProtectionSettings {
  enabled: boolean;
  coverage?: boolean;
  customerClaimsEnabled?: boolean;
  packageProtectionPlusEnabled?: boolean;
  minPrice: number;
  maxPrice: number;
  percentage: number;
  splitProducts: boolean;
  pricingRuleSet?: PricingRuleSet[];
}

interface FinalSaleReturnsSettings {
  enabled: boolean;
  validCollections: string[];
  validProductTags: string[];
  minPrice: number;
  maxPrice: number;
  percentage: number;
  pricingRuleSet?: PricingRuleSet[];
}

export enum ReturnMethod {
  CUSTOMER_SHIPS = "customer_ships",
  CUSTOMER_IN_STORE = "customer_in_store",
  PACKAGE_PICKUP = "package_pickup",
}

export type InternationalReturnPricing = {
  countries: string[];
  multiplier: number;
}[];

export enum ReviewPrioritizationOption {
  HighestPrice = "Highest price",
  LowestReview = "Lowest review",
  HighestReview = "Highest review",
  Random = "Random",
}

export type InstantExchangeHoldKind = "none" | "one_dollar" | "full_cost";

export type DkimStatus = "pending" | "verified" | "failed" | "not_started";

type ContactFormFieldSettings = {
  /** Whether the field is shown in the contact form */
  show: boolean;
  /** Whether the customer is required to fill out the field in order to submit the form */
  required: boolean;
  /** Placeholder text shown before the customer provides a value for the field */
  placeholder: string;
};

export type ContactFormSettings = {
  enabled: boolean;
  title: string;

  name: ContactFormFieldSettings;
  email: Omit<ContactFormFieldSettings, "show" | "required">;
  subject: ContactFormFieldSettings & { options: string[] };
  phone: ContactFormFieldSettings;
  comment: Omit<ContactFormFieldSettings, "show" | "required">;

  allowFileUpload: boolean;

  replyEmail: EmailInfo | undefined;
  successMessage: string;
};

export const contactFormSettingsDefault: ContactFormSettings = {
  enabled: false,
  title: "Contact Us",
  name: { show: true, required: false, placeholder: "Enter your name" },
  email: { placeholder: "Enter your email" },
  subject: {
    show: true,
    required: false,
    placeholder: "Select a subject",
    options: ["General Inquiry", "Order Inquiry", "Feedback", "Other"],
  },
  phone: {
    show: true,
    required: false,
    placeholder: "Enter your phone number",
  },
  comment: {
    placeholder:
      "Leave a comment to help us better know what you're contacting us about.",
  },
  allowFileUpload: false,
  replyEmail: undefined,
  successMessage:
    "Thanks for contacting us. We'll get back to you as soon as possible.",
};

export interface Settings {
  concierge: ConciergeSettings;
  products: {
    barcode?: {
      size: {
        height: { value: Decimal128Extended; unit: string };
        width: { value: Decimal128Extended; unit: string };
      };
    };
  };
  storeUrl: string;
  discountDistributionMethod: DiscountDistributionMethod;
  showPDFInvoiceButton: boolean;
  shopifySubscription: any[];
  inventory: {
    followShopifyInventoryPolicy: boolean;
    minimum_stock: number;
    restock: boolean;
    restockDamaged: boolean;
    restockShopifyRefunds?: boolean;
  };
  labelExpiration: {
    days: number;
    reminderEmails: boolean;
    expirationEmails: boolean;
    reminderEmailDays: number[];
    strategy: LabelExpirationStrategy;
  };
  chatFlow: Json;
  useShadowRootInCart?: boolean;
  cartShadowRootSelector?: string;
  checkboxFontSize?: number;
  checkboxSelector?: string;
  checkboxText?: string;
  claimFlow: Json;
  emailFlows?: {
    _id?: string;
    flow: Json;
  }[];
  /** Hides products in the return portal so they can't be returned */
  portalExcludedProductTags?: string[];
  checkboxTextAfterRedo?: string;
  countries: string[];
  countriesPackageProtection: string[];
  coverage: Coverage;
  coverageEnabled: boolean;
  coverageExcludeCollections: string[];
  coverageExcludeCustomerTags: string[];
  coverageExcludeSources: string[];
  coverageExcludeTags: string[];
  coverageExcludeProductTags: string[];
  coverageExcludeDiscountCodes: string[];
  coverageExcludeProperties: string[];
  createReturnBarcodes?: boolean;
  excludeCoverageIfAnyInvalidProduct?: boolean;
  discountCodePartialMatch?: boolean;
  createdAt: string;
  customerSupport: {
    wrongProduct: CustomerSupportSetting;
    damagedItem: CustomerSupportSetting;
  };
  enabled: boolean;
  exchanges: {
    advancedExchanges: AdvancedExchanges;
    selection: ExchangesSelection;
    allowInstant: boolean;
    instantHoldAmount: InstantExchangeHoldKind;
    instantExpirationDays: number;
    notes: { enabled: boolean };
    suffix: string | null;
    orderName: {
      prefix: string;
      suffix: string;
      useShopifyGeneratedName: boolean;
    };
    outOfStockPolicy: RefundType;
    excessExchangeValue: RefundType;
    outOfStockAdvanced: OutOfStockAdvancedOption;
    excludeProductTags: string[];
    recreateExpiredDiscounts: boolean;
    blockDiscountsOnExchanges: boolean;
    shopSiteURL: string;
    shopSiteParams: string;
    shopSiteDomain: string;
    shopSitePath: string;
    enableOnHoldOrders: boolean;
    onHoldOrdersOnUpsellOnly?: boolean;
    differentPricedVariantExchanges: boolean;
    variantExchangeProductTags: string[];
    usePreDiscountPrice: boolean;
    instantExchangeOnly?: boolean;
    shopSiteOneTab?: boolean;
    reopenExpiredInstantUponShipment?: boolean;
    createLabelOnDraftOrderComplete?: boolean;
  };
  failed_payouts: number;
  hideCheckboxBranding: boolean;
  hideCheckboxPrice: boolean;
  hidePortalBranding: boolean;
  hideCheckbox: boolean;
  images: string[];
  locations: ShippingLocation[];
  returnInStoreEnabled?: boolean;
  merchantCoverage: Coverage;
  returnAdjustment: {
    positiveName: string;
    negativeName: string;
  };
  paidModel: PaymentModel;
  policyType: string;
  productName?: string;
  protectionExcludeTags?: string[];
  receipt_email: string;
  redoAutoCheck: boolean;
  packageProtectionAutoCheck: boolean;
  returnFlow: Json;
  finalizeReturnFlow: Json;
  finalizeClaimFlow: Json;
  warrantyFlow: Json;
  returnLocationId: string;
  returnPortal: string;
  returnPortalHeaderText?: string;
  returnPortalButtonText?: string;
  returnPortalGiftButtonText?: string;
  shippingCarrierOverride: string[];
  shippingCarrierAccounts?: string[];
  shippingInsurance: ShippingInsurance;
  shippingServiceOverride: Map<string, string[]>;
  shopAgainAction: "gift_card" | "discount_code" | "rise" | "shopify_account";
  riseTrigger: { url: string } | null;
  sku?: string;
  sku2?: string;
  returnsSKU?: string;
  packageProtectionSKU?: string;
  combinedSKU?: string;
  uniqueVariantSKU: boolean;
  html?: string;
  updatedAt: string;
  upsert: { enabled: boolean; value: string; type: string };
  widget: { url: string };
  packingSlipEnabled?: boolean;
  deductLabelFromCredit?: boolean;
  deductLabelFromRefund?: boolean;
  cartToggleEnabled: boolean;
  cartToggleSelector?: string;
  cartClickListenerSelector?: string;
  toggleOnColor: string;
  toggleOffColor: string;
  toggleCircleColor: string;
  cartTogglePlacement?: "before" | "after" | "prepend" | "append";
  packingInstructions?: { [key in ReturnMethod]: string[] };
  exchangeGroups: ExchangeGroup[];
  shopifyExtension?: {
    exchangeBannerPositionBottom?: boolean;
    hideRedoProduct?: HideRedoConfig;
    customizationVisible?: boolean;
    customizeCartItem?: boolean;
    brandPrefix?: string;
    logo?: string;
    redoProductImage?: string;
    imageUpdated?: boolean;
    displayIcon?: boolean;
    iconColor?: string;
    iconBackgroundColor?: string;
    icon?: Icons;
    returnNameOverride?: string;
    packageProtectionNameOverride?: string;
    bothProductNameOverride?: string;
    checkoutExtensionEnabled?: boolean;
    attachmentStrategy?: AttachmentStrategy;
    toggleSubtextEnabled?: boolean;
    toggleTextOptions?: {
      returnToggle: ToggleFields;
      packageProtectionToggle: ToggleFields;
      bothProductToggle: ToggleFields;
    };
    infoIconLink?: string;
    customPdpCss?: string;
    customToggleCss?: string;
    forceIPAddressLocation?: boolean;
    coverageExcludePickup?: boolean;
    usingCartAndCheckoutToggle?: boolean;
    checkoutUICustomizations?: CheckoutUICustomizationSettings;
  };
  returns?: {
    enabled: boolean;
    refundAllReturnedItems: boolean;
    dynamicPricing?: DynamicReturnPricing;
    settlementEnabled: boolean;
    settlementRefund?: number;
    multipleLabelsEnabled?: boolean;
    internationalPricing?: InternationalReturnPricing;
    finalSaleReturns?: FinalSaleReturnsSettings;
    allowMultiOrderReturns?: boolean;
    multiOrderReturnLimit?: number;
    disableRejectedItems?: boolean;
    allowReopenAfterProcessing?: boolean;
    hideGiftCardBranding?: boolean;
    oneShipmentPerItemEnabled?: boolean;
    /** For green international returns. shopper will be told to buy their own label.*/
    buyYourOwnLabel?: boolean;
    returnToFulfillmentOrigin?: boolean;
  };
  /** allows merchant to change return address before return is approved */
  canEditReturnAddress?: boolean;
  packageProtection?: PackageProtectionSettings;
  support?: {
    billing?: {
      aiMessageSuggestionFreeTrialDays?: number;
      aiMessageSuggestionFreeTrialUsed?: boolean;
      aiMessageSuggestionAtShopifyCap?: boolean;
      aiMessageSuggestionTicketsUsed?: number;
      aiMessageSuggestionPlanName?: string;
      aiMessageSuggestionPricePerMonth?: number;
      aiMessageSuggestionOveragePrice?: number;
      aiMessageSuggestionTicketsCovered?: number;
      aiMessageSuggestionBillingConfirmed?: boolean;
      aiMessageSuggestionLastDayActive?: string;
    };
    csat?: {
      enabled: boolean;
      surveySendTime?: string;
      surveyMethods: string[];
      allowRemarks?: boolean;
      headerImage?: string;
      surveyMessage?: string;
    };
    closeAction: CloseActionMethod;
    /** Controls the contact form merchants can embed on their website */
    contactForm?: ContactFormSettings;
    receiveEmail?: string;
    senderEmails?: SenderEmail[];
    emailSignature?: string;
    tags?: ConversationTag[];
    // TODO: remove
    bccEmails?: boolean;
    tagSpam?: boolean;
    ticketAssignment: "manual" | "balanced" | "round-robin";
    usersForAutoAssignment: string[];
    autoAssignOnResponse?: boolean;
    autoReassignOnResponse?: boolean;
    maxTicketsPerUser: string;
    metaPagesData?: {
      userId?: string;
      longLivedToken?: string;
      pagesData?: PagesData[];
    };
    excludedEmails?: string[];
    ai?: SupportAiSettings;
    instagram?: {
      autoCloseMentions: boolean;
      autoCloseRepliedStories: boolean;
      autoCloseExternal?: boolean;
    };
    facebook?: {
      autoCloseExternal?: boolean;
      commentsEnabled: boolean;
    };
    propagateDeletes?: boolean;
    faqs?: {
      enabled: boolean;
    };
  };
  customerAccounts?: CustomerAccountsSettings;
  customerWidget?: CustomerWidgetSettings;
  notifyCustomerDefault: boolean;
  merchantPaidEnabledDates?: DateRange[];
  showLabelExpirationDate: boolean;
  modalTextAdjustments: {
    text1?: string;
    title1?: string;
    text2?: string;
    title2?: string;
    text3?: string;
    title3?: string;
  };
  returnBarcodeWidth?: number;
  returnBarcodeHeight?: number;
  modalLogo?: string;
  labelPriceAdjustment?: number | null;
  labelPriceExcludeCoverage?: boolean;
  minimumLabelOunces?: number;
  defaultLineItemOunces?: number;
  toggleFontSize?: number;
  checkoutSelector?: string;
  exchangeBannerColor: string;
  exchangeBannerText: string;
  exchangeBannerFont: string;
  exchangeBannerFontColor: string;
  exchangeBannerShowDoneButton?: boolean;
  submitButtonSelector?: string;
  billingSettings: BillingSettings;
  bundleRulesList: BundleRules[];
  bundles: Bundle[];
  pickupEnabled: boolean;
  shopifyPixelId?: string;
  splitPixelEnabled?: boolean;
  createShopifyReturns?: boolean;
  archiveShopifyOrdersAfterReturn?: boolean;
  instantRefundEnabled?: boolean;
  orderTracking?: {
    enabled: boolean;
    createTrackers: boolean;
    stalledDays?: number;
    stalledDaysFulfillment?: number;
    orderDiscount?: {
      enabled: boolean;
      settings: DiscountSettings;
    };
    showPDPDeliveryEstimate?: boolean;
    useRedoTrackingUrl: boolean;
    ads?: OrderTrackingAd[];
    upsellProducts?: UpsellProductSection;
    internalNotifications?: {
      // TODO (Josh) replace with new advanced flow enums
      [TriggerType.ORDER_STALLED]?: boolean;
      [TriggerType.ORDER_DELAYED]?: boolean;
      [TriggerType.ORDER_ARRIVING_EARLY]?: boolean;
      [TriggerType.ORDER_RETURN_TO_SENDER]?: boolean;
      [TriggerType.ORDER_DELIVERY_ATTEMPTED]?: boolean;
      [TriggerType.ORDER_FAILURE]?: boolean;
      [TriggerType.ORDER_ERROR]?: boolean;
      [TriggerType.ORDER_CANCELLED]?: boolean;

      // [TriggerType.RETURN_STALLED]?: boolean;
      // [TriggerType.RETURN_DELAYED]?: boolean;
      // [TriggerType.RETURN_RETURN_TO_SENDER]?: boolean;
      // [TriggerType.RETURN_DELIVERY_ATTEMPTED]?: boolean;
      // [TriggerType.RETURN_FAILURE]?: boolean;
      // [TriggerType.RETURN_ERROR]?: boolean;
      // [TriggerType.RETURN_CANCELLED]?: boolean;
      email?: string;
    };
    billing?: {
      confirmed?: boolean;
      enabled: boolean;
      continueIfNotAccepted: boolean;
      subscriptionModified?: boolean | null;
      pendingCapRaiseUrl?: string | null;
      pricePerOrder: number;
      freeOrdersPerMonth: number;
      freeUntilDateEnabled: boolean | null;
      freeUntilDate: string | null;
      texts?: {
        enabled: boolean;
        pricePerSms: number;
        pricePerMms: number;
      } | null;
      unbilledUsage?: {
        orderCount?: number | null;
        smsCount?: number | null;
        mmsCount?: number | null;
      } | null;
    } | null;
    emailDomain?: {
      enabled: boolean;
      domain: string;
      fromName?: string;
      fromEmail?: string;
      dkimSelector: string;
      dkimStatus: DkimStatus;
    };
    mostRecentSync?: {
      fulfillmentsAttempted: number;
      fulfillmentsFailed: number;
      syncDate: string;
      ordersFullySynced: number;
      ordersNotFullySynced: number;
    };
    syncInProgress?: boolean;
    orderEditing?: {
      enabled: boolean;
    };
    postPurchaseUpsell?: {
      enabled: boolean;
      type: UpsellProductSource;
      collection?: CollectionInfo;
      discountEnabled: boolean;
      discountType: DiscountValueType;
      discountAmount: string;
    };
  };
  returnTracking?: {
    enabled: boolean;
    ads?: OrderTrackingAd[];
    upsellProducts?: UpsellProductSection;
    disableDefaultEmails: boolean;
  };
  reviews?: {
    enabled: boolean;
    response?: {
      moderationSettings?: ModerationSettings;
      incentivesSettings?: {
        enableDiscount?: boolean;
        discountForReview?: { enabled: boolean; total: number };
        discountForPhoto?: { enabled: boolean; total: number };
        discountForVideo?: { enabled: boolean; total: number };
        discountForSocial?: { enabled: boolean; total: number };
        customDiscountCode?: string;
        discountExpirationDays?: number;
        minimumPurchaseAmount?: number;
      };
    };
  };
  outboundLabels: {
    enabled: boolean;
    serviceLevelMappings: {
      merchantServiceLevel: string;
      redoServiceLevel: ServiceLevel[];
    }[];
    defaultUpcharge?: Upcharge;
    serviceLevelUpcharges?: ServiceLevel[];
    savedParcels?: Parcel[];
    defaultParcel?: Parcel;
    verifyAddresses?: boolean;
    saveTestShipments?: boolean;
    billing?: {
      test?: boolean;
      autoReload?: {
        threshold: number;
        amount: number;
      };
      fundingSources?: PaymentMethod[];
      activeFundingSources?: {
        primary?: FundingSourceReference;
        secondary?: FundingSourceReference;
      };
    };
  };
  marketing?: {
    enabled: boolean;
    texts?: {
      enabled: boolean;
      phoneNumber: string;
      phoneNumberVerified: boolean;
    };
    testingMode?: boolean;
  };
  variantExchangeTitle?: string;
  exchangeGroupsExchangeTitle?: string;
  sameItemTitle?: string;
  newItemTitle?: string;
  preProtectedOrdersEnabled: boolean;
  preProtectedOrderMinutes?: number;
  _id: string;
  isPaidByMerchant(): boolean;
  resourceOverride?: LanguageResourceOverride;
  customerPortalVersion?: CustomerPortalVersion;
  enableNonShopifyClaims?: boolean;
  nonShopifyClaimsCollection?: {
    id: string;
    name: string;
  };
  nonShopifyClaimsDisableEditQuantity?: boolean;
  convertRecyclingProducts?: boolean;
  hideManualReviewStep?: boolean;
  hideSummaryCard?: boolean;
  hideGiftOption?: boolean;
  disableRefundLineItems: boolean;
  warranties: {
    enabled: boolean;
    warrantyRegistrationCollection?: string;
    customersCanStartClaims: boolean;
    registrationEnabled: boolean;
    flatRateRepairEnabled: boolean;
    flatRateRepairPrice: string;
  };
  brandKit?: BrandKit;
  autoProcessGreenReturns: boolean;
  hideFeeInCompensationMethodModal?: boolean;
}

export function isPreProtectedOrdersEnabled({
  preProtectedOrdersEnabled,
  preProtectedOrderMinutes,
}: {
  preProtectedOrdersEnabled: boolean;
  preProtectedOrderMinutes: number | null | undefined;
}): boolean {
  return (
    isPreProtectedOrdersAllowed(preProtectedOrderMinutes) &&
    preProtectedOrdersEnabled
  );
}

/**
 * As of Oct 3, 2024, for pre-protected orders to be allowed to be enabled, someone at Redo must
 * have set the preProtectedOrderMinutes to a value greater than 0. Once preProtectedOrderMinutes
 * is set, the preProtectedOrdersEnabled setting can be used to toggle the feature on and off.
 */
export function isPreProtectedOrdersAllowed(
  preProtectedOrderMinutes: number | null | undefined,
): boolean {
  return !!preProtectedOrderMinutes && preProtectedOrderMinutes > 0;
}

export type UpsellProductSection = {
  source: UpsellProductSource;
  hoverSecondImage?: boolean;
  collectionId?: number;
  collectionName?: string;
  metafield?: {
    key: string;
    namespace: string;
    metaobjectKey?: string;
  };
  metafieldKey?: string;
  text?: string;
  minimumStock?: number;
  productTags?: string[];
  productEstimateEnabled?: boolean;
};

export type FundingSourceReference =
  | {
      source: "outbound_labels";
      id: string;
    }
  | {
      source: "general";
      priority: "primary" | "secondary" | "card";
    };

export interface OrderTrackingAd {
  imageUrl: string;
  url: string;
  altText: string;
  type: AdLinkType;
  showOnlyNonSubscribed: boolean;
  duplicate: () => OrderTrackingAd | undefined;
}

export const orderTrackingAdEqual: Equal<OrderTrackingAd> =
  objectEqual<OrderTrackingAd>({
    imageUrl: stringEqual,
    url: stringEqual,
    altText: stringEqual,
    type: stringEqual,
    showOnlyNonSubscribed: booleanEqual,
    duplicate: optionalEqual(() => true),
  });

export interface HideRedoConfig {
  enabled: boolean;
  productSelector?: string;
  cartCountModifications?: CartCountModification[];
  cartPriceModifications?: CartCountModification[];
  cartDiscountModifications?: CartCountModification[];
}

export interface CartCountModification {
  selector: string;
  search: string;
  replace: string;
}

export interface ModerationSettings {
  autoPublish?: boolean;
  ratingLevel?: number;
  blockUnverifiedReviews?: boolean;
  blockMediaReviews?: boolean;
  blockProfanityReviews?: boolean;
  blockNegativeSentimentReviews?: boolean;
  publishAfterTime?: number;
  excludeReturnReviewers?: boolean;
  excludedCustomerTags?: string[];
  excludedProducts?: string[];
  reviewPrioritization?: ReviewPrioritizationOption;
}

export interface ToggleFields {
  titleText: string;
  positiveSubtext: string;
  negativeSubtext: string;
}

export interface CollectionInfo {
  id: string;
  name: string;
  kind: "smart" | "custom";
}

export type ExchangeGroupType =
  | "tag:tag"
  | "tag:collection"
  | "name:collection"
  | "collection:collection";

export type PriceDifferenceType = "ignore" | "apply";

export interface BaseExchangeGroup {
  type: ExchangeGroupType;
  groupName: string;
  priceDifference: PriceDifferenceType;
}

export interface TagForTagGroup extends BaseExchangeGroup {
  type: "tag:tag";
  sourceTags: string[];
  targetTags: string[];
}

export interface NameForCollectionGroup extends BaseExchangeGroup {
  type: "name:collection";
  sourceName: string;
  targetCollection: CollectionInfo;
}

export interface TagForCollectionGroup extends BaseExchangeGroup {
  type: "tag:collection";
  sourceTags: string[];
  targetCollection: CollectionInfo;
}

export interface CollectionForCollectionGroup extends BaseExchangeGroup {
  type: "collection:collection";
  sourceCollection: CollectionInfo;
  targetCollection: CollectionInfo;
}

export type ExchangeGroup =
  | TagForTagGroup
  | NameForCollectionGroup
  | TagForCollectionGroup
  | CollectionForCollectionGroup;

export const exchangeGroupEqual: Equal<ExchangeGroup> =
  objectEqual<ExchangeGroup>({
    type: stringEqual,
    groupName: stringEqual,
    priceDifference: stringEqual,
    sourceName: optionalEqual(stringEqual),
    sourceTags: optionalEqual(arrayEqual(stringEqual)),
    targetTags: optionalEqual(arrayEqual(stringEqual)),
    sourceCollection: optionalEqual(
      objectEqual<CollectionInfo>({
        id: stringEqual,
        name: stringEqual,
        kind: stringEqual,
      }),
    ),
    targetCollection: optionalEqual(
      objectEqual<CollectionInfo>({
        id: stringEqual,
        name: stringEqual,
        kind: stringEqual,
      }),
    ),
  });

export enum AdvancedExchanges {
  ENABLED = "all",
  /** Limits advanced exchanges to items with value less than or equal to the exchange value */
  LESS_THAN_EQUAL = "less_than_equal",
  DISABLED = "variant_only",
}

export type Onboarding = any;

export interface Address {
  name: string;
  street1: string;
  street2?: string;
  city: string;
  zip: string;
  state?: string;
  country: string;
  country_name?: string;
  phone?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface CustomerSupportSetting {
  ask: boolean;
  ifYes: string;
  then: string;
}

export interface Provinces {
  country: CountryCode;
  provinceCodes: string[];
}

export interface ShippingLocation {
  flowTypes: string[];
  condition: LocationCondition | null;
  multipleChoiceQuestion: string | null;
  multipleChoiceQuestionAnswers: string[] | null;
  returnReasons: string[] | null;
  originalLocation: boolean;
  address?: Address;
  locationId: string;
  tags: string[] | null;
  collections: string[] | null;
  discounts: string[] | null;
  priceMatchType: PriceOption | null;
  price: string | null;
  skuMatchType: SkuOption | null;
  skus: string[] | null;
  salesChannels: string[] | null;
  countries: string[] | null;
  provinces: Provinces | null;
}

export interface Portal {
  _id: string;
  /** @deprecated use brand kit instead */
  font_family: string;
  /** @deprecated use brand kit instead */
  title_font_family: string;
  /** @deprecated use brand kit instead */
  body_font_family: string;
  shippingFeeText: string;
  /** Maybe should be in brand kit but not deprecated right now */
  custom_css: string;
  /** @deprecated use brand kit instead */
  font_weight: 100 | 300 | 400 | 500 | 700 | 900;
  /** @deprecated use brand kit instead */
  button_color: string;
  /** @deprecated use brand kit instead */
  accent_color: string;
  /** @deprecated use brand kit instead */
  accent_background_color: string;
  /** @deprecated use brand kit instead */
  home_text_color: string;
  enable_alert: boolean;
  notification: string;
  returnButtonText: string;
  claimButtonText: string;
  notExchangeButtonText?: string;
  exchangeButtonText?: string;
  standard_exchange_text?: string;
  hideDiscountCodes: boolean;
  instant_exchange_text: string;
  privacy_link: string;
  /** Maybe should be in brand kit but not deprecated right now */
  background_url: string | null;
  /** @deprecated use brand kit instead */
  favicon_url: string | null;
  /** @deprecated use brand kit instead  */
  logo_url: string | null;
  /** Maybe should be in brand kit but not deprecated right now */
  colors: string[];
  /** Maybe should be in brand kit but not deprecated right now */
  injection_text_color: string;
  header?: string;
  footer?: string;
  green_return_text?: string;
  custom_confirmation_text?: string;
  green_return_confirmation_text?: string;
  green_return_confirmation_description?: string;
  exchangeOptionText?: string;
  storeCreditOptionText?: string;
  refundOptionText?: string;
  claimReviewHeader?: string;
  claimShippingLineItemText?: string;
  domain?: string;
  pathPrefix: string;
  cantFindOrderText?: string;
  claimSummarySubtext?: string;
  warrantySummarySubtext?: string;
  returnSummarySubtext?: string;
  hideOrderStatusUrl?: boolean;
  hideRateYourExperienceWidget?: boolean;
  hideLoginOrderNumberTooltip?: boolean;
  collapseVariantThreshold?: number;
}

export interface TeamUser {
  roles: string[];
  _id: string;
  user: string | GetUser;
  email: string;
  createdAt: string;
  updatedAt: string;
}

export interface RenderedTeamUser {
  roles: string[];
  _id: string;
  user: GetUser;
  email: string;
  createdAt: string;
  updatedAt: string;
}

export type RenderedTeam = Omit<Team, "users"> & {
  users: RenderedTeamUser[];
};

export type RefundType = "store_credit" | "refund";
export type OutOfStockAdvancedOption = "continue" | "cancel" | "oversell";

/**
 * Which type of return Status the automation will wait for to automatically process the return/claim.
 * @see import("./return").Status
 */
export type ProcessEvent =
  | "complete" // None (manually process)
  | "open" // Return created
  | "in_transit" // Shipment in transit
  | "delivered"; // Shipment delivered

export interface SenderEmail {
  email: string;
  name: string;
  postmarkId: number;
  dkimHost: string;
  dkimText: string;
  returnPathDomain: string;
  returnPathValue: string;
  setupStatus: string;
}

export interface BillingSettings {
  schedule: "bimonthly" | "weekly";
  dayOfWeek?: number;
  firstBillingDayOfMonth?: number;
}

interface CustomerAccountsSettings {
  enabled: boolean;
  loyaltyEnabled?: boolean | undefined;
  webPixelEnabled?: boolean;
  // Manual DB setting, no UI. One off request for merchant with new Swym contract
  // for wishlists.
  hideLists?: boolean;
  adBlocks?: AdBlock[];
}

interface ConciergeSettings {
  enabled: boolean;
  general?: {
    avatarUrl?: string;
    name?: string;
    toneOfVoice?: string;
    writingExamples?: string[];
    brandDescription?: string;
    customerDescription?: string;
    outputLength?: ResponseLength;
  };
}

interface CustomerWidgetSettings {
  general?: {
    enableInAppEmbed?: boolean;
    widgetQuickLinks?: QuickLink[];
    selfServe?: {
      startReturnEnabled: boolean;
      startClaimEnabled: boolean;
      cancelOrderEnabled: boolean;
    };
    showCustomerWidgetInReturnApp?: boolean;
    homeHeadingText?: string;
    headingLogoUrl?: string;
    hideWidgetAfterHours?: boolean;
    widgetFontFamily?: string;
    widgetColor?: string;
    widgetPosition?: "left" | "right";
    xAdjustment?: string;
    yAdjustment?: string;
    widgetHoursEnabled?: boolean;
    widgetHoursTimezone?: string;
    widgetHours?: {
      sunday: {
        enabled: boolean;
        start: string | null;
        end: string | null;
      };
      monday: {
        enabled: boolean;
        start: string | null;
        end: string | null;
      };
      tuesday: {
        enabled: boolean;
        start: string | null;
        end: string | null;
      };
      wednesday: {
        enabled: boolean;
        start: string | null;
        end: string | null;
      };
      thursday: {
        enabled: boolean;
        start: string | null;
        end: string | null;
      };
      friday: {
        enabled: boolean;
        start: string | null;
        end: string | null;
      };
      saturday: {
        enabled: boolean;
        start: string | null;
        end: string | null;
      };
    };
  };
  support?: {
    chatExpirationMinutes?: number;
    chatExpirationMessage?: string;
    unresponsiveConversionMinutes?: number;
    unresponsiveConversionSeconds?: number;
    unresponsiveConversionEnabled?: boolean;
    unresponsiveConversionEmail?: EmailInfo;
    hideSupportWidgetFeatures?: boolean;
    messagingHeadingText?: string;
  };
  customerAccounts?: {
    registerHeader?: string;
    registerSubtext?: string;
    hideButton?: boolean;
    accountTabSections?: AccountTabSection[];
  };
}

export interface TeamInfo {
  id: string;
  widgetSlug: string;
  returnsEnabled: boolean;
  customerClaimsEnabled: boolean;
  packageProtectionPlusEnabled: boolean;
  orderTrackingEnabled: boolean;
  csat: {
    enabled: boolean;
    surveyMethods: string[];
    allowRemarks: boolean;
  };
  customerAccountsEnabled: boolean;
  accountTab: {
    registerHeader: string;
    registerSubtext: string;
    sections: AccountTabSection[];
  };
  hideButton: boolean;
  multipassAvailable: boolean;
  supportFeaturesEnabled: boolean;
  url: string;
  chatHeadingLogoUrl: string;
  storeUrl: string;
  storefrontAccessToken: string;
  customerPortalButtonText?: string;
  returnButtonText: string;
  claimButtonText: string;
  messagingHeadingText: string;
  homeHeadingText: string;
  contactFormSettings?: ContactFormSettings;
  name: string;
  chatFlow: Json;
  widgetQuickLinks?: QuickLink[];
  selfServe: {
    startReturnEnabled: boolean;
    startClaimEnabled: boolean;
    cancelOrderEnabled: boolean;
  };
  hideWidgetAfterHours?: boolean;
  widgetPrimaryColor?: string;
  widgetFontFamily?: string;
  widgetPosition?: "left" | "right";
  xAdjustment?: string;
  yAdjustment?: string;
  widgetHoursTimezone?: string;
  widgetHoursEnabled?: boolean;
  widgetHours?: {
    sunday?: {
      enabled: boolean;
      start: string;
      end: string;
    };
    monday?: {
      enabled: boolean;
      start: string;
      end: string;
    };
    tuesday?: {
      enabled: boolean;
      start: string;
      end: string;
    };
    wednesday?: {
      enabled: boolean;
      start: string;
      end: string;
    };
    thursday?: {
      enabled: boolean;
      start: string;
      end: string;
    };
    friday?: {
      enabled: boolean;
      start: string;
      end: string;
    };
    saturday?: {
      enabled: boolean;
      start: string;
      end: string;
    };
  };
}

export class OrderTrackingSettings {
  constructor(private readonly teamSettings: Team["settings"]) {}

  withinUsageCap() {
    if (!this.teamSettings.orderTracking?.billing?.enabled) {
      return true;
    }
    const pricingDetails =
      this.teamSettings.shopifySubscription?.[0]?.lineItems.find(
        (lineItem: any) =>
          lineItem.plan.pricingDetails.__typename === "AppUsagePricing",
      )?.plan?.pricingDetails;
    if (!pricingDetails) {
      return false;
    }
    const balanceUsed = Number(pricingDetails?.balanceUsed?.amount ?? 0);
    const cappedAmount = Number(pricingDetails?.cappedAmount?.amount ?? 0);
    return balanceUsed < cappedAmount;
  }

  servicesActive() {
    if (!this.teamSettings?.orderTracking?.enabled) {
      return false;
    }

    return (
      (this.teamSettings?.orderTracking?.billing?.confirmed &&
        this.withinUsageCap()) ||
      this.teamSettings?.orderTracking?.billing?.continueIfNotAccepted
    );
  }

  canSendTexts() {
    return (
      this.servicesActive() &&
      (this.teamSettings?.orderTracking?.billing?.texts?.enabled ||
        !this.teamSettings?.orderTracking?.billing?.enabled)
    );
  }
}

export const teamCanUseAi = (team?: Team) => {
  const ai = team?.settings?.support?.ai;
  const billing = team?.settings?.support?.billing;
  if (ai && billing) {
    const billingConfirmed = billing.aiMessageSuggestionBillingConfirmed;
    const atShopifyCap = billing.aiMessageSuggestionAtShopifyCap;
    const lastDayActive = billing.aiMessageSuggestionLastDayActive;
    const lastDayActiveIsInFuture = lastDayActive
      ? new Date(lastDayActive) > new Date()
      : false;

    return (
      ai.enabled &&
      ai.copilotEnabled &&
      (billingConfirmed || lastDayActiveIsInFuture) &&
      !atShopifyCap
    );
  }
  return false;
};
